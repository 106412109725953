<template>
  <div class="app" ref="refocus" tabindex="-1">
    <!-- <Header /> -->
    <slot />
    <!-- <Footer /> -->
    <!-- <GetQuoteButton /> -->
    <!-- <EIModal /> -->
  </div>
</template>

<script setup>
useHead({
  script: [
    {
      src: 'https://assets.calendly.com/assets/external/widget.js',
      async: true
    }
  ]
})
</script>

<style lang="scss">
.app {
  &:focus {
    outline: 0;
  }
}
</style>